import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/zY4X2nl1u6E">
    <SEO title="Promise - Christmas in Canaan" />
  </Layout>
)

export default SermonPost
